import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button, Collapse } from "react-bootstrap";
import Logo from "../../assets/Images/BrandLogo/Logo.png";
import useContentful from "../../useContentful";
import migration_agent_registration_number from "../../assets/Images/AboutUs/migration_agent_registration_number.jpg";

export const Footer = () => {
  const [Useopen, UsesetOpen] = useState(false);
  const [Copen, CsetOpen] = useState(false);
  const [Aopen, AsetOpen] = useState(false);
  const [contactDetails, setContactDetails] = useState({})
  const { getContactDetail } = useContentful()
  useEffect(() => {
    getContactDetail().then((response) => {
      setContactDetails(response)
    })
  }, []);
  return (
    <>
      <div className="footer">
        <Container>
          <Row className="Footerbox">
            <Col xl={4} lg={4} md={6} sm={12}>
              <img className="migration_agent_img" src={migration_agent_registration_number}></img>
              <div>
                <button className="button">
                  <a href="https://www.mara.gov.au/tools-for-agents-subsite/Files/code-of-conduct-march-2022.pdf" target="_blank">Code of conduct</a>
                </button>
              </div>
            </Col>
            <Col xl={4} lg={4} md={3}>
              <div className="F-col-2 footer-text FooterVertical">
                <h3 className=" footerLinkHeading">
                  Useful Links
                  <Button
                    className="Footer-button"
                    onClick={() => UsesetOpen(!Useopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Useopen}
                  ></Button>
                </h3>
                <Collapse in={Useopen}>
                  <ul className="list-unstyled">
                    <li>
                      <a href="#aboutme">About Me</a>
                    </li>

                    <li>
                      <a href="#service">Services</a>
                    </li>
                    {/* <li>
                      <a href="#blog">Blog</a>
                    </li> */}
                    <li>
                      <a href="#contact">Contact</a>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </Col>
            {/* <Col xl={2} lg={2} md={2}>
              <div className="F-col-3 footer-text FooterVertical">
                <h3 className=" footerLinkHeading">
                  Services
                  <Button
                    className="Footer-button"
                    onClick={() => CsetOpen(!Copen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Copen}
                  ></Button>
                </h3>
                <Collapse in={Copen}>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/service">Help</Link>
                    </li>
                    <li>
                      <Link to="/faq">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/planing">Terms of use</Link>
                    </li>
                    <li>
                      <Link to="/contact">Support</Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </Col> */}

            <Col xl={4} lg={4} md={4} sm={12}>
              <div className="F-col-4 footer-text FooterVertical ">
                <h3 className="footerLinkHeading">
                  Contact Me
                  <Button
                    className="Footer-button"
                    onClick={() => AsetOpen(!Aopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Aopen}
                  ></Button>
                </h3>
                <Collapse in={Aopen}>
                  <div className="quickContact">
                    <div className="contactUsIconContain">
                      <i className="fa fa-map-marker"></i>
                      <span>
                        <a className="footer-contact-link" href="http://maps.google.com/?q=Suite 42 48/50 Goerge Street, Parramatta" target="_blank">{contactDetails?.location}</a>
                      </span>
                    </div>
                    <div className="contactUsIconContain">
                      <i className="fas fa-phone-alt"></i>
                      <span>
                        <a className="footer-contact-link" href={`tel:${contactDetails?.mobileNumber}`} >{contactDetails?.mobileNumber}</a>

                      </span>
                    </div>
                    <div className="contactUsIconContain">
                      <i className="fas fa-envelope"></i>
                      <span>
                        <a className="footer-contact-link" href={`mailto:${contactDetails?.email}`} >{contactDetails?.email}</a>

                      </span>
                    </div>
                  </div>
                </Collapse>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
        <h6 className="F-copywrite">Copyright © 2023 . All Rights Reserved</h6>
      </div>
    </>
  );
};
export default Footer;
