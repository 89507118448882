import React from "react";
import "../../../theme/css/Service.css";
import useContentful from "../../../useContentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import {
  Col,
  FormControl,
  InputGroup,
  Modal,
  Row,
  Button,
} from "react-bootstrap";
export default function Service({ selectedService, handleClose }) {
  const { getServices } = useContentful();

 

  return (
    <>
    <Modal.Body>
      
      <div>
        <img src={selectedService?.image}></img>
        <h3>{selectedService?.title}</h3>

      </div>
      <div dangerouslySetInnerHTML={{ __html: documentToHtmlString(selectedService?.description) }}>

      </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <button className="button" >
                  <a href = "https://visasbysanat.as.me/schedule.php" target = "_blank">
                  Book Appointment</a>
                </button>
      </Modal.Footer>
    </>
  );
}
