import React from "react";
import Banner from "../HomePage/Banner";
import AboutUs from "../HomePage/AboutUs";
import OurBlog from "./OurBlog";
import Brandlogo from "./Brandlogo";
import Contact from "../Contact/Contact";
import OurBestServices from "./OurBestServices";
export default function Home() {
  return (
    <>
      <Banner />
      <div id="aboutme">
        <AboutUs />
      </div>
      <div id="service">
        <OurBestServices></OurBestServices>
      </div>
      {/* <div id="blog">
        <OurBlog />
      </div> */}
     
      <div id="contact">
        <Contact />
      </div>
    </>
  );
}
