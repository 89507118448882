import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../components/pages/HomePage/Home";
import Service from "../components/pages/Service/Service";
import AboutUsMenu from "../components/pages/AboutUs/AboutUsMenu";
import Blog from "../components/pages/Blog/Blog";
import { BlogPage } from "../components/pages/Blog/BlogPage";
export default function Index() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/service/:id" element={<Service />} />
        {/* <Route path="/portfolio" element={<Portfolio />} /> */}
        {/* <Route path="/faq" element={<FAQ />} /> */}
        {/* <Route path="/planing" element={<Planing />} /> */}
        <Route path="/blog" element={<Blog />} />
        {/* <Route path="/blogpage/:id" element={<BlogPage />} /> */}
        <Route path="/aboutus" element={<AboutUsMenu />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </div>
  );
}
