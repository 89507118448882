import React, { useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
const Header = (props) => {
  var className = "inverted";
  const [addClass, setAddClass] = useState(" ");
  const [showDropdown, setShowDropdown] = useState(false);
  var scrollTrigger = 20;

  const onToggle = () => {
    document.getElementById("myOverlay").style.display = "block";
    document.getElementById("stamenu").className = "active";
  };

  const onCloseNav = () => {
    document.getElementById("myOverlay").style.display = "none";
    document.getElementById("stamenu").className = "";
  };

  window.onscroll = function () {
    setAddClass();
    if (
      window.scrollY >= scrollTrigger ||
      window.pageYOffset >= scrollTrigger
    ) {
      document.getElementsByTagName("nav")[0].classList.add(className);
    } else {
      document.getElementsByTagName("nav")[0].classList.remove(className);
    }
  };

  return (
    <>
      <div className={addClass}>
        <div className="header">
          <div className="  sticky-top " id="stamenu">
            <Navbar expand="lg" collapseOnSelect>
              <div className="container">
                <div className="inner-header">
                  <Navbar.Brand href="/">
                    {/* <img
                      src={Logo}
                      alt="themeLogo"
                      className="img-fluid mx-auto WLogo"
                      style={{height: '40px'}}
                    /> */}
                    {/* <img
                      src={Logo}
                      alt="themeLogo"
                      style={{height: '40px'}}
                      className="img-fluid mx-auto BLogo"
                    /> */}
                    <h1 class="pancakes-text WLogo">Sanat Gupta </h1>
                    <h1 class="pancakes-text BLogo">Sanat Gupta </h1>
                  </Navbar.Brand>
                  <Button
                    className="btn-navbar navbar-toggle"
                    onClick={onToggle}
                    data-toggle="collapse"
                    data-target=".nabar-ex1-collapse"
                  >
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </Button>

                  <div id="mySidenav" className="sidenav menu-vertical">
                    <div className="close-nav hidden-md hidden-lg hidden-xl ">
                      <span>Menu</span>
                      <Button
                        className="closebtn pull-right"
                        onClick={onCloseNav}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </Button>
                    </div>

                    <Nav
                      className="justify-content-center"
                      id="myOverlay"
                      style={{ flex: 1 }}
                    >
                      <Nav.Link href="home">
                        <Link to="/"> Home </Link>
                      </Nav.Link>
                      <Nav.Link href="#contact">Contact Me</Nav.Link>
                      <Nav.Link href="#service">
                        {/* <Link to="/service"> */}
                        Service
                        {/* </Link> */}
                      </Nav.Link>
                      {/* <Nav.Link href="#blog"> */}
                        {/* <Link to="/blog"> */}
                        {/* Blog */}
                        {/* </Link> */}
                      {/* </Nav.Link> */}
                      <Nav.Link href="#aboutme">
                        {/* <Link to="/aboutus"> */}
                        About Me
                        {/* </Link> */}
                      </Nav.Link>
                    </Nav>
                  </div>
                </div>
              </div>
            </Navbar>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
