import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import useContentful from "../../../useContentful";
export default function AboutUs() {
  const [aboutMe, setAboutMe] = useState({});
  const { getAboutMeDetail } = useContentful();
  useEffect(() => {
    getAboutMeDetail().then((response) => {
      setAboutMe(response)})
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="container">

        <div className="aboutus spacing">
          <div className="titleFont">
            <h3 className="details-summary">About Me</h3>

          </div>
          <Row className="align-items-center">
            <Col
              xl={10}
              lg={10}
              md={10}
              sm={12}
              xs={12}
              data-aos="fade-up"
              className="details-summary"
            >
              <div className="titleFont">
                <h2>{aboutMe?.title}</h2>
              </div>
              <p className="paragraph">
              {aboutMe?.detail}
              </p>

              {/* <button className="button">
                <a href="#contact">Contact Me</a>
              </button> */}
            </Col>
           

          </Row>
        </div>
      </div>
    </>
  );
}
