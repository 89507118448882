import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import useContentful from "../../../useContentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import Service from "../Service/Service";
import {
  Col,
  Modal,
  Card,
} from "react-bootstrap";
export default function OurBestServices() {
  const { getServices } = useContentful();
  const [servicesData, setServicesData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedService, setSelectedService] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setSelectedService(data)
    setShow(true)
      ;
  }
  useEffect(() => {
    getServices().then((response) => {
      setServicesData(response)
    })
  }, [])

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1900,
    autoplay: true,
    autoplaySpeed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],

  }
  return (
    <>
      <div className="ourBestServicesBanner">
        <div className="container">
          <div className="ourBestServices">
            <div className="titleFont">
              <h3>Our Best Services</h3>
              <h2>We're ready to share our advice and experience.</h2>
            </div>
            <div className="ourBestServicesRow beffect">
              <Slider {...settings} className="row">
                {servicesData?.map((service) => <Col sm={4} xs={12}>
                  <Card data-aos="fade-up"  >
                    <Card.Body >
                      <div className="service-card">
                        <div className="bimg">
                          <Card.Img
                            className="img-fluid mx-auto"
                            variant="top"
                            src={service?.image}
                          />
                        </div>
                        <div className="titleFont text-ellipsis" >

                          <h3>{service?.title}</h3>

                          <span dangerouslySetInnerHTML={{ __html: documentToHtmlString(service?.description) }}>
                          </span>
                        </div>
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <button onClick={() => handleShow(service)} className="homeBannerBtn ourSurvice button">
                        Read More
                      </button>
                    </Card.Footer>
                  </Card>
                </Col>)}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        fullscreen="xxl-down"
      >
        <Service selectedService={selectedService} handleClose={handleClose} />
      </Modal>
    </>
  );
}
