import React, { useEffect, useState } from "react";
import "../../../theme/css/Contactus.css";
import { Row, Col } from "react-bootstrap";
import Iframe from "react-iframe";
import Aos from "aos";
import "aos/dist/aos.css";
import ContactUs from "./ContactUs";
import useContentful from "../../../useContentful";
export default function Contact() {
  const [contactDetails, setContactDetails] = useState({})
  const {getContactDetail} = useContentful()
  useEffect(() => {
    Aos.init({ duration: 2000 });
    getContactDetail().then((response) => {
      setContactDetails(response)})
  }, []);
  return (
    <>
      <div className="otherPageBanner contactUsBanner">
        <div className="text-center title" data-aos="fade-down">
          <h2>CONTACT ME</h2>
          <h6>Let's discuss your query now</h6>
        </div>
      </div>
      <div className="container">
        <div className="contactContain">
          <Row data-aos="flip-down">
            <Col lg={3} md={3} sm={3} xl={3}>
              <div className="contactIconBgShadow text-center">
                <i className="fas fa-search-location"></i>
                <h2> Visit my office</h2>
                <h6>
                  <p>{contactDetails?.location}</p>
                  <p>{contactDetails?.state}</p>
                </h6>
              </div>
            </Col>
            <Col lg={3} md={3} sm={3} xl={3}>
              <div className="contactIconBgShadow text-center">
                <i className="fas fa-headphones-alt"></i>
                <h2> Call me directly</h2>
                <h6>
                  <p>Phone: {contactDetails?.mobileNumber}</p>
                  
                  {/* <p>Fax: 1-800-222-002</p> */}
                </h6>
              </div>
            </Col>
            <Col lg={3} md={3} sm={3} xl={3}>
              <div className="contactIconBgShadow text-center">
                <i className="fas fa-envelope-open-text"></i>
                <h2>E-mail me</h2>
                <h6>
                  <p>{contactDetails?.email}</p>
                </h6>
              </div>
            </Col>
            <Col lg={3} md={3} sm={3} xl={3}>
              <div className="contactIconBgShadow text-center">
                <i className="fas fa-calendar-check	"></i>
                <p>
                <button className="button" style={{marginTop: '10px'}}>
                  <a href = "https://visasbysanat.as.me/schedule.php" target = "_blank">
                  Book Appointment</a>
                </button>
                  </p>
               
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <ContactUs />
      <div className="row">
        <div className="col appointment">
  
      </div>
      </div>
    </>
  );
}
