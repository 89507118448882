import { createClient } from "contentful"
const useContentful = (

) => {
    const client = createClient({
        space: "hglroun0bt3d",
        accessToken: "AIA9QF9DuxYMJoPJmA9YlKv1X6zapNIxXTbge7jK20g",
        host: "preview.contentful.com"
    });
    const getContactDetail = async () => {
        try {
            const contactentries = await client.getEntries({
                content_type: 'contact',
                select: 'fields',

            });
            const sanitizedContactDetail = await contactentries?.items[0].fields
            return sanitizedContactDetail
            
        }
        catch (error) {
            console.log('error fetching', error)
        }
    }
    const getBlogData = async () => {
        try {
            const Blogentries = await client.getEntries({
                content_type: 'blog',
                select: 'fields',

            });
            const sanitizedBlogData = await Blogentries?.items?.map((item,index) => {
                const image = item?.fields.image?.fields?.file?.url
                return { ...item?.fields, image, id:index }
            })

            return sanitizedBlogData
        }
        catch (error) {
            console.log('error fetching', error)
        }
    }

    const getServices= async () => {
        try {
            const servicesEntries = await client.getEntries({
                content_type: 'services',
                select: 'fields',

            });
            const sanitizedServicesData = await servicesEntries?.items?.map((item,index) => {
                const image = item?.fields.image?.fields?.file?.url;
                const description = item?.fields?.description;

                return { ...item?.fields, image, description, id:index }
            })
console.log(sanitizedServicesData);
            return sanitizedServicesData
        }
        catch (error) {
            console.log('error fetching', error)
        }
    }

    const getAboutMeDetail = async () => {
        try {
            const aboutMeEntries = await client.getEntries({
                content_type: 'aboutMe',
                select: 'fields',

            });
            const sanitizedAboutMeDetail = await aboutMeEntries?.items[0]?.fields
            const detail = await sanitizedAboutMeDetail?.detail?.content[0]?.content[0]?.value
            return { ...sanitizedAboutMeDetail, detail }
        }
        catch (error) {
            console.log('error fetching', error)
        }
    }
    return { getContactDetail, getAboutMeDetail, getBlogData, getServices }
}
export default useContentful