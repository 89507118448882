import React from "react";
import "../../../../src/theme/css/HomePage.css";
import sanat_photo1 from "../../../assets/Images/Banner/sanat_photo1.jpg";

export default function Home() {
  return (
    <>
      <div className="banner">
        <div className="banner-details">
          <div className="w-50 banner-all-details">
            <div className="text-width">
              <h2 className="text-capitalize">Sanat Gupta</h2>
              {/* <h3 className="text-capitalize">As needed.</h3> */}
              <p>
                Choose me for faster and better way to get your all kind of visa
                done.
              </p>
              <button className=" button">
                <a href="https://visasbysanat.as.me/schedule.php" target="__blank"> Book an Appointmnent </a>
              </button>
            </div>
          </div>
          <div className="w-50">
            <img
              className="img-fluid mx-auto banner-bg"
              src={sanat_photo1}
              alt="BannerImage"
            />
          </div>
        </div>
      </div>
    </>
  );
}
